var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('div',{staticClass:"signin-page"},[_c('div',{staticClass:"flex-direction window-height page-layout"},[_vm._m(0),_c('div',{staticClass:"right-side-view col-grow app-text-normal"},[_c('div',{staticClass:"column signin-container q-pa-md"},[_c('div',{staticClass:"row justify-between items-center"},[(_vm.stage > 1 && _vm.showCompanyName)?_c('div',{staticClass:"flex"},[(_vm.company.logo)?_c('q-avatar',{attrs:{"size":"50px"}},[_c('img',{attrs:{"src":_vm.company.logo,"alt":_vm.company.name}})]):_c('q-avatar',{staticClass:"text-bold",attrs:{"size":"50px","color":"blue-grey-4","text-color":"white","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm._f("first2Char")(_vm.company.name))+" ")])],1):_c('div',{staticClass:"flex"},[_c('img',{staticClass:"cursor-pointer",attrs:{"alt":"logo","src":"/static/images/logo/logo.svg"},on:{"click":_vm.redirectToSite}})]),_c('div',{staticClass:"top-right-text app-text-normal",staticStyle:{"font-weight":"500"}},[_c('div',[_vm._v(" Not a member?"),_c('span',{staticClass:"redirect-text q-ml-xs cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'SignupView' })}}},[_vm._v("Sign up now")])]),(_vm.showCompaniesList !== 0)?_c('div',{staticClass:"small-view-margin"},[(_vm.showCompaniesList === 1)?_vm._l((_vm.companies),function(company,key){return _c('router-link',{key:key,staticClass:"bg-primary text-white",staticStyle:{"border":"1px solid"},attrs:{"tag":"q-btn","to":{
                    name: 'BoardView',
                    params: { company: key, workspace: 1 },
                  }}},[_vm._v(_vm._s(company.companyName))])}):_vm._e(),(_vm.showCompaniesList > 1)?_c('q-btn',{attrs:{"label":"Companies","icon-right":_vm.$icons.matArrowDropDown,"color":"primary","size":"md","no-caps":""}},[_c('q-menu',{attrs:{"content-class":"overflow-auto app-text-normal"}},[_c('q-list',{staticStyle:{"min-width":"150px"}},_vm._l((_vm.companies),function(company,key){return _c('q-item',{key:key,attrs:{"clickable":""},on:{"click":function($event){return _vm.switchCompany(key)}}},[_c('q-item-section',[_vm._v(" "+_vm._s(company.companyName))])],1)}),1)],1)],1):_vm._e()],2):_vm._e()])]),_c('div',{staticClass:"col-grow row justify-center child-items-center"},[(_vm.stage <= 1 && !_vm.forgotPasswordDialog)?_c('div',[_c('form',{staticClass:"field-width",on:{"submit":function($event){$event.preventDefault();_vm.company.uri != '' ? _vm.checkCompanyUri() : null}}},[_c('div',{staticClass:"sigin-company-name app-text-normal",staticStyle:{"margin-bottom":"25px"}},[_vm._v(" Sign in ")]),_c('div',{staticClass:"input-label app-text-normal"},[_vm._v("Team name:")]),_c('div',{staticClass:"relative-position"},[_c('q-input',{ref:"focus",attrs:{"type":"text","error":_vm.fieldErrors('company.uri').length > 0,"no-error-icon":"","dense":"","outlined":"","bottom-slots":"","maxlength":"40"},on:{"input":function($event){_vm.$v.company.uri.$touch();
                    _vm.errors.checkCompanyUri = null;},"blur":function($event){return _vm.$v.company.uri.$touch()},"keyup":_vm.replaceSpace},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"signing-help",staticStyle:{"color":"#c1dcf3"},attrs:{"name":_vm.$icons.matHelp,"clickable":""},nativeOn:{"click":function($event){$event.stopPropagation();_vm.forgotCompanyDialog = true}}})]},proxy:true},{key:"error",fn:function(){return undefined},proxy:true}],null,false,2884299159),model:{value:(_vm.company.uri),callback:function ($$v) {_vm.$set(_vm.company, "uri", $$v)},expression:"company.uri"}})],1),(_vm.errors.checkCompanyUri)?_c('div',{staticClass:"text-negative"},[_vm._v(" "+_vm._s(_vm.errors.checkCompanyUri)+" ")]):_vm._e(),_c('q-btn',{staticClass:"signin-btn-class app-text-normal",attrs:{"type":"submit","no-caps":"","text-color":"white","label":"Continue","padding":"0","disabled":this.company.uri ? false : true,"loading":_vm.loader.stage1}})],1),_c('div',{staticClass:"row justify-center items-center member-mobile-view q-mt-md"},[_c('div',{staticClass:"app-text-normal",staticStyle:{"font-weight":"500","color":"#000"}},[_vm._v(" Not a member?"),_c('span',{staticClass:"redirect-text q-ml-xs cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'SignupView' })}}},[_vm._v("Sign up now")])])])]):_vm._e(),(_vm.stage == 2 && !_vm.forgotPasswordDialog)?_c('div',[_c('form',{staticClass:"field-width",on:{"submit":function($event){$event.preventDefault();_vm.$v.$invalid ? null : _vm.singIn()}}},[_c('div',{staticClass:"sigin-company-name app-text-normal"},[_vm._v(" Sign in to "+_vm._s(_vm.company.name)+" ")]),_c('div',{staticClass:"input-label app-text-normal"},[_vm._v("Email")]),_c('q-input',{ref:"focus",staticClass:"app-text-normal",attrs:{"autofocus":"","type":"text","error":_vm.fieldErrors('email').length > 0,"bottom-slots":"","no-error-icon":"","dense":"","outlined":""},on:{"input":function($event){_vm.$v.email.$touch();
                  _vm.errors.singIn = null;},"blur":function($event){return _vm.$v.email.$touch()}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('div',{staticClass:"text-negative app-text-normal"},[_vm._v(" "+_vm._s(_vm.fieldErrors("email").length > 0 ? _vm.fieldErrors("email")[0] : "")+" ")])]},proxy:true}],null,false,1980586865),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"q-mt-sm input-label row justify-between items-center app-text-normal"},[_vm._v(" Password "),_c('span',{staticClass:"forgot-password cursor-pointer app-text-normal",on:{"click":function($event){_vm.forgotPasswordDialog = true}}},[_vm._v("Forgot Password")])]),_c('q-input',{ref:"focus",attrs:{"type":"password","name":"password","error":_vm.fieldErrors('password').length > 0,"bottom-slots":"","no-error-icon":"","dense":"","outlined":""},on:{"input":function($event){_vm.$v.password.$touch();
                  _vm.errors.singIn = null;},"blur":function($event){return _vm.$v.password.$touch()}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('div',{staticClass:"text-negative"},[_vm._v(" "+_vm._s(_vm.fieldErrors("password").length > 0 ? _vm.fieldErrors("password")[0] : "")+" ")])]},proxy:true}],null,false,2611990622),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"text-negative app-text-normal"},[_vm._v(_vm._s(_vm.errors.singIn))]),_c('q-btn',{staticClass:"signin-btn-class app-text-normal",attrs:{"type":"submit","no-caps":"","text-color":"white","label":"Sign In","padding":"0","loading":_vm.loader.stage2,"disabled":_vm.isDisabledStage2}})],1)]):_vm._e(),(_vm.forgotPasswordDialog)?_c('div',[_c('forgot-password',{attrs:{"companyDetail":_vm.company,"showTopCompanyName":_vm.showCompanyNameHandler},on:{"success":_vm.forgotPasswordSuccessHandler}})],1):_vm._e()])])]),_c('q-dialog',{attrs:{"transition-hide":"none","transition-show":"none"},model:{value:(_vm.forgotCompanyDialog),callback:function ($$v) {_vm.forgotCompanyDialog=$$v},expression:"forgotCompanyDialog"}},[_c('q-card',{staticStyle:{"width":"380px"}},[_c('forgot-company',{attrs:{"closeForgotCompanyHandler":_vm.closeForgotCompany},on:{"success":_vm.forgetCompanyHandler}})],1)],1),(_vm.termsConditionDialog)?_c('terms-condition-dialog',{attrs:{"defaultView":_vm.defaultView},model:{value:(_vm.termsConditionDialog),callback:function ($$v) {_vm.termsConditionDialog=$$v},expression:"termsConditionDialog"}}):_vm._e()],1)]):_c('div',[_c('mobile-singin-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-side-view text-white"},[_c('img',{staticClass:"image",attrs:{"src":"/static/images/create-company/step1.jpg"}})])
}]

export { render, staticRenderFns }