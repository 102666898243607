<template>
  <div class="signin-page" v-if="!isMobile">
    <div class="flex-direction window-height page-layout">
      <!-- <div @click="redirectToSite" class="mobile-view cursor-pointer">
        <img alt="logo" src="/static/images/signin/heycollab_logo_white.svg" />
      </div> -->
      <div class="left-side-view text-white">
        <img
          class="image"
          src="/static/images/create-company/step1.jpg"
        />
        <!-- <img
          @click="redirectToSite"
          style="margin-top: 80px;"
          class="cursor-pointer"
          alt="logo"
          src="/static/images/signin/heycollab_logo_white.svg"
        />
        <div style="margin-top: 110px;">
          <div class="big-text">
            <div>Work.</div>
            <div>In harmony.</div>
          </div>
          <div class="small-text q-pr-xl q-mt-md app-text-normal">
            A new project coordination tool for teams
          </div>
        </div>
        <div class="row bottom-content absolute app-text-normal">
          <div
            @click="openAgreementDialog('policy')"
            class="q-mr-md cursor-pointer"
          >
            Privacy Policy
          </div>
          <div @click="openAgreementDialog('terms')" class="cursor-pointer">
            Terms of use
          </div>
        </div> -->
      </div>
      <div
        class="right-side-view col-grow app-text-normal"
      >
        <div class="column signin-container q-pa-md">
          <div class="row justify-between items-center">
            <div
              class="flex"
              v-if="stage > 1 && showCompanyName"
            >
              <q-avatar size="50px" v-if="company.logo">
                <img :src="company.logo" :alt="company.name" />
              </q-avatar>
              <q-avatar
                size="50px"
                color="blue-grey-4"
                text-color="white"
                class="text-bold"
                font-size="18px"
                v-else
              >
                {{ company.name | first2Char }}
              </q-avatar>
            </div>
            <div v-else class="flex">
              <img
                @click="redirectToSite"
                alt="logo"
                class="cursor-pointer"
                src="/static/images/logo/logo.svg"
              />
            </div>
            <div class="top-right-text app-text-normal" style="font-weight: 500;">
              <div>
                Not a member?<span
                  class="redirect-text q-ml-xs cursor-pointer"
                  @click="$router.push({ name: 'SignupView' })"
                  >Sign up now</span
                >
              </div>
              <div v-if="showCompaniesList !== 0" class="small-view-margin">
                <template v-if="showCompaniesList === 1">
                  <router-link
                    tag="q-btn"
                    class="bg-primary text-white"
                    style="border:1px solid"
                    v-for="(company, key) in companies"
                    :key="key"
                    :to="{
                      name: 'BoardView',
                      params: { company: key, workspace: 1 },
                    }"
                    >{{ company.companyName }}</router-link
                  >
                </template>
                <q-btn
                  label="Companies"
                  :icon-right="$icons.matArrowDropDown"
                  color="primary"
                  size="md"
                  no-caps
                  v-if="showCompaniesList > 1"
                >
                  <q-menu content-class="overflow-auto app-text-normal">
                    <q-list style="min-width:150px">
                      <q-item
                        v-for="(company, key) in companies"
                        :key="key"
                        clickable
                        @click="switchCompany(key)"
                      >
                        <q-item-section> {{ company.companyName }}</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </div>
            </div>
          </div>
          <div class="col-grow row justify-center child-items-center">
            <div v-if="stage <= 1 && !forgotPasswordDialog">
              <form
                class="field-width"
                @submit.prevent="company.uri != '' ? checkCompanyUri() : null"
              >
                <div
                  class="sigin-company-name app-text-normal"
                  style="margin-bottom:25px"
                >
                  Sign in
                </div>
                <div class="input-label app-text-normal">Team name:</div>
                <div class="relative-position">
                  <q-input
                    ref="focus"
                    type="text"
                    v-model="company.uri"
                    @input="
                      $v.company.uri.$touch();
                      errors.checkCompanyUri = null;
                    "
                    @blur="$v.company.uri.$touch()"
                    :error="fieldErrors('company.uri').length > 0"
                    v-on:keyup="replaceSpace"
                    no-error-icon
                    dense
                    outlined
                    bottom-slots
                    maxlength="40"
                  >
                  <template v-slot:append>
                    <q-icon :name="$icons.matHelp" @click.native.stop="forgotCompanyDialog = true" clickable style="color:#c1dcf3" class="signing-help" />
                  </template>
                    <template v-slot:error>
                      <!-- <div class="text-negative">
                        {{
                          fieldErrors("company.uri").length > 0
                            ? fieldErrors("company.uri")[0]
                            : ""
                        }}
                      </div> -->
                    </template>
                  </q-input>
                  <!-- <q-btn
                    flat
                    :icon="$icons.matHelp"
                    size="md"
                    class="absolute"
                    style="top:0px;right:0px;color:#c1dcf3"
                    @click.native.stop="forgotCompanyDialog = true"
                    round
                  /> -->
                </div>
                <div v-if="errors.checkCompanyUri" class="text-negative">
                  {{ errors.checkCompanyUri }}
                </div>
                <q-btn
                  type="submit"
                  no-caps
                  text-color="white"
                  label="Continue"
                  padding="0"
                  class="signin-btn-class app-text-normal"
                  :disabled="this.company.uri ? false : true"
                  :loading="loader.stage1"
                />
              </form>
              <div class="row justify-center items-center member-mobile-view q-mt-md">
                <div class="app-text-normal" style="font-weight: 500;color: #000;" >
                  Not a member?<span
                  class="redirect-text q-ml-xs cursor-pointer"
                  @click="$router.push({ name: 'SignupView' })"
                  >Sign up now</span>
                </div>
              </div>
            </div>
            <div v-if="stage == 2 && !forgotPasswordDialog">
              <form
                class="field-width"
                v-on:submit.prevent="$v.$invalid ? null : singIn()"
              >
                <div class="sigin-company-name app-text-normal">
                  Sign in to {{ company.name }}
                </div>
                <div class="input-label app-text-normal">Email</div>
                <q-input
                  class="app-text-normal"
                  autofocus
                  ref="focus"
                  type="text"
                  v-model="email"
                  @input="
                    $v.email.$touch();
                    errors.singIn = null;
                  "
                  @blur="$v.email.$touch()"
                  :error="fieldErrors('email').length > 0"
                  bottom-slots
                  no-error-icon
                  dense
                  outlined
                >
                  <template v-slot:error>
                    <div class="text-negative app-text-normal">
                      {{
                        fieldErrors("email").length > 0
                          ? fieldErrors("email")[0]
                          : ""
                      }}
                    </div>
                  </template>
                </q-input>
                <div
                  class=" q-mt-sm input-label row justify-between items-center app-text-normal"
                >
                  Password
                  <span
                    @click="forgotPasswordDialog = true"
                    class="forgot-password cursor-pointer app-text-normal"
                    >Forgot Password</span
                  >
                </div>
                <q-input
                  ref="focus"
                  type="password"
                  name="password"
                  v-model="password"
                  @input="
                    $v.password.$touch();
                    errors.singIn = null;
                  "
                  @blur="$v.password.$touch()"
                  :error="fieldErrors('password').length > 0"
                  bottom-slots
                  no-error-icon
                  dense
                  outlined
                >
                  <template v-slot:error>
                    <div class="text-negative">
                      {{
                        fieldErrors("password").length > 0
                          ? fieldErrors("password")[0]
                          : ""
                      }}
                    </div>
                  </template>
                </q-input>
                <div class="text-negative app-text-normal">{{ errors.singIn }}</div>
                <q-btn
                  type="submit"
                  no-caps
                  text-color="white"
                  label="Sign In"
                  padding="0"
                  class="signin-btn-class app-text-normal"
                  :loading="loader.stage2"
                  :disabled="isDisabledStage2"
                />
              </form>
            </div>
            <div v-if="forgotPasswordDialog">
              <forgot-password
                @success="forgotPasswordSuccessHandler"
                :companyDetail="company"
                :showTopCompanyName="showCompanyNameHandler"
              ></forgot-password>
            </div>
          </div>
        </div>
      </div>
      <q-dialog
        transition-hide="none"
        transition-show="none"
        v-model="forgotCompanyDialog"
      >
        <q-card style="width:380px">
          <forgot-company
            @success="forgetCompanyHandler"
            :closeForgotCompanyHandler="closeForgotCompany"
          ></forgot-company>
        </q-card>
      </q-dialog>

      <!-- Terms and condition dialog -->
      <terms-condition-dialog
        v-model="termsConditionDialog"
        v-if="termsConditionDialog"
        :defaultView="defaultView"
      />
    </div>
  </div>
  <div v-else>
    <mobile-singin-view> </mobile-singin-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import has from "lodash/has";
import size from "lodash/size";
import { login, getUser, getWorkspaces } from "@/services/auth";

import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import validationMixin from "@/mixins/validationMixin";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { validCompanyUri } from "@/utils/validators";

import MobileSinginView from "@/views/MobileSigninView";
import ForgotCompany from "@/components/ForgotCompany";
import ForgotPassword from "@/components/ForgotPassword";
import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";

import {
  CompanyQuery,
  UserActionLogsMutation,
  Workspaces2Query,
  CheckCompanyStatusMutation,
} from "@/gql";

const initialState = () => {
  return {
    stage: 0,
    errors: {
      checkCompanyUri: null,
      singIn: null,
    },
    company: {
      id: null,
      uri: null,
      name: null,
      public_link: null,
    },
    email: null,
    password: null,
    forgotCompanyDialog: false,
    forgotPasswordDialog: false,
    loader: {
      stage1: false,
      stage2: false,
    },
    defaultView: null,
    termsConditionDialog: false,
    showCompanyName: true,
    isMobile:false,
  };
};

export default {
  name: "SigninView",
  mixins: [validationMixin, ProxyModelMixin],
  validations: {
    email: { required, email },
    password: { required },
    company: {
      uri: {
        required,
        validCompanyUri,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
    },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
    password: {
      required: "message.validation.password.required",
    },
    company: {
      uri: {
        required: "message.validation.company.uri.required",
        maxLength: "message.validation.company.uri.max",
        minLength: "message.validation.company.uri.min",
        validCompanyUri: "message.validation.company.uri.validurl",
      },
    },
  },
  components: {
    ForgotCompany,
    ForgotPassword,
    TermsConditionDialog,
    MobileSinginView,
  },
  data() {
    return initialState();
  },
  methods: {
    replaceSpace() {
      this.company.uri = this.company.uri.replace(/[\W_]/g, "-");
    },
    showCompanyNameHandler() {
      this.showCompanyName = false;
    },
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    redirectToSite() {
      window.open("https://heycollab.com/");
    },
    async checkCompanyUri() {
      this.loader.stage1 = true;
      const variables = {
        uri: this.company.uri,
      };

      try {
        const response = await this.$api.query({
          query: CompanyQuery,
          variables,
        });

        const company = response.data.company;
        if (!company) {
          this.errors.checkCompanyUri = this.$t(
            "message.validation.company.notExist"
          );
        } else {
          // Route to dashboard if company already authenticated
          if (has(this.companies, company.uri)) {
            this.$store.dispatch("activeCompany", company.uri);
            this.$store.dispatch("companyStatus", {
              isActive: company.status,
              subscriptionOver: false,
              graceHours: 0,
              hasPaycard: true,
            });
            this.handleLoggedInCompany(company);
            this.loader.stage1 = false;
          } else {
            this.company.id = company.id;
            this.company.name = company.name;
            this.company.public_link = company.public_link;
            this.company.pricing_version = company.pricing_version;
            this.company.logo = company.logo;
            this.company.status = company.status;
            this.stage = 2;
          }
        }
        this.loader.stage1 = false;
      } catch (error) {
        this.errors.checkCompanyUri = "Something went wrong.";
        this.loader.stage1 = false;
      }
    },
    async singIn() {
      if(!this.checkMobile()){
        this.isMobile = false;
        this.loader.stage2 = true;
        let credential = {
          username: this.email,
          password: this.password,
          company: this.company.uri,
        };
        const result = await login(credential);
        if (has(result, "error")) {
          this.errors.singIn = this.$t("message.validation.credentialNotMatch");
        } else {
          const user = await getUser(result.access_token);
          if (user.status === "inactive") {
            this.errors.singIn =
              user.company.user_id === user.id
                ? this.$t("message.validation.ownerInactive")
                : this.$t("message.validation.memberInactive");
          } else {
            const { data } = await getWorkspaces(
              result.access_token,
              Workspaces2Query
            );
            let redirectWorkspaceId = null;
            const filteredWorkspaces = data.workspaces2.filter(
              (w) => !w.is_general && w.type !== "people" && w.type !== "personal"
            );
            if (filteredWorkspaces.length > 0) {
              redirectWorkspaceId = filteredWorkspaces[0].id;
            } else {
              redirectWorkspaceId = 1;
            }
            const shareData = {
              companyUri: this.company.uri,
              companyName: this.company.name,
              companyId: this.company.id,
              logo: this.company.logo,
              pricing_version: this.company.pricing_version,
              accessToken: result.access_token,
              refreshToken: result.refresh_token,
              walkthrough: user.last_session_at <= user.created_at,
              redirectWorkspaceId,
              status: this.company.status,
              isActive: this.company.status === "active",
            };
            this.$store.dispatch("addToCompanies", shareData);
            this.$store.dispatch("activeCompany", this.company.uri);
            this.$store.dispatch(
              "companyStatus",
              this.company.status === "active"
            );

            const checkCompany = await this.$api.mutate({
              mutation: CheckCompanyStatusMutation,
            });
            const status = {
              isActive:
                checkCompany.data.checkCompanyStatus.company_status === "active",
              subscriptionOver:
                checkCompany.data.checkCompanyStatus.subscription_over,
              trialOver: checkCompany.data.checkCompanyStatus.trial_over,
              graceHours: checkCompany.data.checkCompanyStatus.grace_hours,
              hasPaycard: checkCompany.data.checkCompanyStatus.hasPaycard,
            };
            this.$store.dispatch("companyStatus", status);

            this.handleLoginSuccess(shareData);
            let variables = {
              action: "login",
              user_id: user.id,
            };
            await this.$api.mutate({
              mutation: UserActionLogsMutation,
              variables,
            });
          }
        }
        this.loader.stage2 = false;
      } else {
        this.isMobile = true;
      }
    },
    handleLoggedInCompany(company) {
      if (company && company.status === "active") {
        this.$router.push({
          name: "BoardView",
          params: {
            company: company.uri,
            workspace: 1,
          },
        });
      } else {
        this.$router.push(`/companies/${company.uri}/payment`);
      }
    },
    handleLoginSuccess(company) {
      if (company) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: this.$t("message.company.login"),
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        if (company.isActive) {
          this.$router.push({
            name: "BoardView",
            params: {
              company: company.companyUri,
              workspace: company.redirectWorkspaceId,
            },
          });
        } else {
          this.$router.push(`/companies/${company.companyUri}/payment`);
        }
      }
    },
    forgotPasswordSuccessHandler() {
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("message.company.forgot.password"),
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
      this.showCompanyName = true;
      this.forgotPasswordDialog = false;
    },
    forgetCompanyHandler(data) {
      if (data) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: this.$t("message.company.forgot.name"),
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.forgotCompanyDialog = false;
      }
    },
    closeForgotCompany() {
      this.forgotCompanyDialog = false;
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    switchCompany(activeCompanyUri) {
      this.$store.dispatch("activeCompany", activeCompanyUri);
      this.$router.push({
        name: "BoardView",
        params: {
          company: activeCompanyUri,
          workspace: 1,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
    }),
    showCompaniesList() {
      return size(this.companies);
    },
    isDisabledStage1() {
      if (this.$v.company.uri.$invalid || this.loader.stage1 === true) {
        return true;
      }
      return false;
    },
    isDisabledStage2() {
      if (
        this.$v.email.$invalid ||
        this.$v.password.$invalid ||
        this.loader.stage2 === true
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    proxyModel(val) {
      if (!val) return;
      this.$track.page("virtual/signin");
      requestAnimationFrame(() => {
        this.$refs.focus.focus();
      });
    },
  },
};
</script>
